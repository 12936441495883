$(document).on('click', '[id^="download_all_button"]', function () {
  download_all($(this));
})

function download_all(self) {
  // console.log("[download_all] %o", self);
  var self_id = self.attr("id");
  var dl_id = self_id.substr(self_id.lastIndexOf("_") + 1);
  var dl_model = self_id.substr(0, self_id.lastIndexOf("_"));
  dl_model = dl_model.substr(dl_model.lastIndexOf("_") + 1);  // 'examination' or 'client'
  $.ajax({
    type: 'GET',
    url: '/' + dl_model + '/' + dl_id + '/download',
    data: {},
    dataType: 'json'
  })
    .done(function (download_files) {
      // console.log(download_files)
      download_files.forEach(function (value, idx) {
        // console.log(value);
        const response = {
          file_id: value[0],
          file_name: value[1].substr(value[1].lastIndexOf('/') + 1),
        }
        setTimeout(() => {
          const a = document.createElement('a');
          a.href = '/' + dl_model + '/' + dl_id + '/download_each?i=' + response.file_id
          a.download = response.file_name;
          a.target = '_blank';
          // console.log(a);
          a.click();
          a.remove();
        }, idx * 50)
      })
    })
}
