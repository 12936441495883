
$('#client-modal').on("hide.bs.modal", function() {
    console.log("test");
});

$(document).on("change", '[id="rent_change_request_rent_with_comma"]', function (event) {
    var rent = convert_str($("#rent_change_request_rent_with_comma").val());

    var common_charges = $("#rent_change_request_common_charges").val();
    var water_bill = $("#rent_change_request_water_bill").val();
    var parking_charges = $("#rent_change_request_parking_charges").val();
    var others = $("#rent_change_request_others").val();
    var others2 = $("#rent_change_request_others2").val();
    var all_fee = Number(rent) + Number(common_charges) + Number(water_bill) + Number(parking_charges) +
        Number(others) + Number(others2);
    set_all_guaranteed(all_fee);
});
$(document).on("change", '[id="rent_change_request_common_charges_with_comma"]', function (event) {
    var common_charges = convert_str($("#rent_change_request_common_charges_with_comma").val());

    var rent = $("#rent_change_request_rent").val();
    var water_bill = $("#rent_change_request_water_bill").val();
    var parking_charges = $("#rent_change_request_parking_charges").val();
    var others = $("#rent_change_request_others").val();
    var others2 = $("#rent_change_request_others2").val();
    var all_fee = Number(rent) + Number(common_charges) + Number(water_bill) + Number(parking_charges) +
        Number(others) + Number(others2);
    set_all_guaranteed(all_fee);
});
$(document).on("change", '[id="rent_change_request_water_bill_with_comma"]', function (event) {
    var water_bill = convert_str($("#rent_change_request_water_bill_with_comma").val());

    var rent = $("#rent_change_request_rent").val();
    var common_charges = $("#rent_change_request_common_charges").val();
    var parking_charges = $("#rent_change_request_parking_charges").val();
    var others = $("#rent_change_request_others").val();
    var others2 = $("#rent_change_request_others2").val();
    var all_fee = Number(rent) + Number(common_charges) + Number(water_bill) + Number(parking_charges) +
        Number(others) + Number(others2);
    set_all_guaranteed(all_fee);
});
$(document).on("change", '[id="rent_change_request_parking_charges_with_comma"]', function (event) {
    var parking_charges = convert_str($("#rent_change_request_parking_charges_with_comma").val());

    var rent = $("#rent_change_request_rent").val();
    var common_charges = $("#rent_change_request_common_charges").val();
    var water_bill = $("#rent_change_request_water_bill").val();
    var others = $("#rent_change_request_others").val();
    var others2 = $("#rent_change_request_others2").val();
    var all_fee = Number(rent) + Number(common_charges) + Number(water_bill) + Number(parking_charges) +
        Number(others) + Number(others2);
    set_all_guaranteed(all_fee);
});
$(document).on("change", '[id="rent_change_request_others_with_comma"]', function (event) {
    var others = convert_str($("#rent_change_request_others_with_comma").val());

    var rent = $("#rent_change_request_rent").val();
    var common_charges = $("#rent_change_request_common_charges").val();
    var water_bill = $("#rent_change_request_water_bill").val();
    var parking_charges = $("#rent_change_request_parking_charges").val();
    var others2 = $("#rent_change_request_others2").val();
    var all_fee = Number(rent) + Number(common_charges) + Number(water_bill) + Number(parking_charges) +
        Number(others) + Number(others2);
    set_all_guaranteed(all_fee);
});
$(document).on("change", '[id="rent_change_request_others2_with_comma"]', function (event) {
    var others2 = convert_str($("#rent_change_request_others2_with_comma").val());

    var rent = $("#rent_change_request_rent").val();
    var common_charges = $("#rent_change_request_common_charges").val();
    var water_bill = $("#rent_change_request_water_bill").val();
    var parking_charges = $("#rent_change_request_parking_charges").val();
    var others = $("#rent_change_request_others").val();
    var all_fee = Number(rent) + Number(common_charges) + Number(water_bill) + Number(parking_charges) +
        Number(others) + Number(others2);
    set_all_guaranteed(all_fee);
});

function convert_str(val) {
    // 全角０..９を半角0..9に変更
    var returnval = val.replace(/[０-９]/g, function (s) {
        return String.fromCharCode(s.charCodeAt(0) - 0xFEE0);
    });
    // 先頭の0を除外。0..9以外を除外
    returnval = returnval.replace(/^[0]+/g, '').replace(/[^0-9]/g, '');
    return returnval
}

function set_all_guaranteed(val) {
    // 3桁ごとに「,」を追加
    $("#rent_change_request_all_guaranteed").val(val);
    $("#rent_change_request_all_guaranteed_with_comma").val($("#rent_change_request_all_guaranteed").val().replace(/(\d)(?=(\d\d\d)+$)/g, "$1,"));
}


$(document).on("change", '[id="rent_change_request_select_date"]', function (event) {
    var button = document.getElementById("memory_button");
    button.style.display = "block";


    var select_date = new Date($("#rent_change_request_select_date").val() + '-01');
    var today = new Date();
    console.log(select_date);
    console.log(today);

    if (select_date < today) {
        $("#CautionText").text('レントエールに直接連絡をお願いします');
        button.style.display = "none";
    } else {
        $("#CautionText").text('');
        button.style.display = "block";
    }
});

