

  $(document).on('turbolinks:load',function(){

  $('#renter_zipcode').jpostal({
    postcode : [
      '#renter_zipcode'
    ],
    address: {
      "#renter_address": "%3%4%5", // # 都道府県が入力される
      //"#user_city"           : "%4%5", // # 市区町村と町域が入力される
      //"#user_street"         : "%6%7" // # 大口事務所の番地と名称が入力される
    }
  });
  $('#manager_zipcode').jpostal({
    postcode : [
      '#manager_zipcode'
    ],
    address: {
      "#manager_address": "%3%4%5", // # 都道府県が入力される
      //"#user_city"           : "%4%5", // # 市区町村と町域が入力される
      //"#user_street"         : "%6%7" // # 大口事務所の番地と名称が入力される
    }
  });
  if($('#additional_contract_manager_kinds').length) {
    ChangeBox($('#additional_contract_manager_kinds'));
  }
  });
  
  $(document).on("change", '[id="additional_contract_manager_kinds"]', function() {
    ChangeBox(this);
    });

function ChangeBox(obj){
  
console.log('changebox');

var selectedval=$(obj).val();
console.log(selectedval);

var box1 = document.getElementById("additional_contract_box1");
var box2 = document.getElementById("additional_contract_box2");
var box3 = document.getElementById("additional_contract_box3");
var box4 = document.getElementById("additional_contract_box4");
var box5 = document.getElementById("additional_contract_box5");
var box6 = document.getElementById("additional_contract_box6");

if (selectedval=="another"){
  box1.style.display = "block";
  box2.style.display = "block";
  box3.style.display = "block";
  box4.style.display = "block";
  box5.style.display = "block";
  box6.style.display = "block";
}
else if (selectedval=="owner"){
  box1.style.display = "none";
  box2.style.display = "block";
  box3.style.display = "block";
  box4.style.display = "block";
  box5.style.display = "block";
  box6.style.display = "block";
}
else {
  box1.style.display = "none";
  box2.style.display = "none";
  box3.style.display = "none";
  box4.style.display = "none";
  box5.style.display = "none";
  box6.style.display = "none";
}
} 
