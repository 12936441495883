$(document).on("change", '[id="arrearage_select_date"]', function(event) {
  ChangeCaution($(this));
  });
  
function ChangeCaution(obj){
  const date = new Date();
  var today_month = new Date(date.getFullYear(),date.getMonth(),1);
  
var customer_id=($("#arrearage_顧客コード").val());
var select_date=obj.val();
var select_year=select_date.substr(0,4);
var select_month=select_date.substr(-2);
var select_date=new Date(select_year,select_month,1);
select_date.setMonth( select_date.getMonth() -1);

console.log(select_date);
console.log(today_month);

var button = document.getElementById("memory_button");
button.style.display = "block";

if (select_date>today_month){
  $("#CautionText").text('現在の日付を超えています');
  button.style.display = "none";
}
else{
  $("#CautionText").text('');
}

}