$(document).on("change", '[id^="tainou_batch_tainou_kohos_attributes"]', function() {
  SetCheckMark($(this));
});

$(document).on("keydown", '[id^="syunou_batch_syunou_kohos_attributes"]', function (e) {
  //今フォーカスがあたっている行を取得
  let $row = $(this).closest("tr");
  var colIndex = $(this).parent().index();
  if (e.keyCode === 9) {
      //-----TAB-----
      //次の行を取得
      let $row_next = $row.next("tr");
      if ($row_next.length) {
        //次の行のフォーカスにセット
        $row_next.find("td").eq(colIndex).find("input").focus();
      }
  } else if (e.keyCode === 38) {
      //-----上-----
      //前の行を取得
      let $row_next = $row.prev("tr");
      if ($row_next.length) {
        //次の行のフォーカスにセット
        $row_next.find("td").eq(colIndex).find("input").focus();
      }
  }
});

function SetCheckMark(obj) {
  var self_id = obj.attr("id");
  // console.log(self_id);
  if (self_id.match(/_target$/) != null) {
    return;
  }
  var key_length = "tainou_batch_tainou_kohos_attributes_".length;
  var target_chack_id = self_id.slice(key_length, self_id.length);
  target_chack_id = target_chack_id.slice(0, target_chack_id.indexOf("_"));
  target_chack_id = "tainou_batch_tainou_kohos_attributes_" + target_chack_id + "_target";
  // console.log(target_chack_id);
  $("#" + target_chack_id).prop('checked', true);
  // console.log($("#" + target_chack_id));
}

//// new -> confirm -> return or submit

$(document).on("turbolinks:load", function () {
  $('#tainou_batch_submit').hide();
  $('#tainou_batch_return').hide();

  $('#tainou_batch_confirm').on("click", function() {
    $(this).hide();
    $('#tainou_batch_return').show();
    $('#tainou_batch_submit').show();
    hide_unckecked_rows();
    $("input[type='text']").prop('disabled', true);
    console.log($(":input+ :text"));
  })
  $('#tainou_batch_return').on("click", function() {
    $(this).hide();
    $('#tainou_batch_submit').hide();
    $('#tainou_batch_confirm').show();
    show_all_rows();
    $("input[type='text']").prop('disabled', false);
  })
  $('#tainou_batch_select_date').on("change", function() {
    $('#tainou_batch_submit').hide();
    $('#tainou_batch_confirm').show();
    reset_all_rows();
  })

  // $('#hide_unchecked').on("click", function() {
  //   if ($(this).prop("checked") == true) {
  //     hide_unckecked_rows();
  //   }
  //   else {
  //     show_all_rows();
  //   }
  // });

  function check_ajax (client_id,yearmonth) {
    var returnval;
    var param = {
                        client_id: client_id,
                        yearmonth: yearmonth
                };
    var url = '/arrearages/ajax';

jQuery.ajax({
method: "POST",
            url: url,
            data: param,
            dataType: 'json',
            async: false
        }).done(function(data) {
    // 通信成功時の処理
    returnval = data;

    // console.log("URL : " + url);
    // console.log("data : " + data);

}).fail(function(jqXHR, textStatus, errorThrown) {
    // 通信失敗時の処理
    console.log("ajax通信に失敗しました");
    console.log("jqXHR          : " + jqXHR.status); // HTTPステータスが取得
    console.log("textStatus     : " + textStatus);    // タイムアウト、パースエラー
    console.log("errorThrown    : " + errorThrown.message); // 例外情報
    console.log("URL            : " + url);
    return null;
}).always(function(data) {
    // 処理終了時
});
return returnval;
}
function tainou_batches_check(this_tr){
  var client_id=this_tr.children("td").eq(4).children(0).val();
  var yearmonth = $("#tainou_batch_select_date").val().replace("-","");
  console.log(client_id);
  console.log(yearmonth);
  var returnjson=check_ajax(client_id,yearmonth);
  console.log(returnjson);
  // var suidou=this_tr.children("td").eq(6).children(0).val();
  // console.log(suidou);
  // if ((typeof suidou === "undefined")||(suidou==0)) {
  //   this_tr.children("td").eq(0).text("水道代を入力してください");
  // }
  if(returnjson.length>0){
    this_tr.children("td").eq(0).text("既に申請されています");
    this_tr.children("td").eq(1).children(0)[1].checked =false;
    this_tr.children("td").eq(1).children(0)[1].disabled =true;
  }
  // console.log($(this).children("td").eq(0));
}

  function hide_unckecked_rows () {
    // console.log("#hide_unchecked checked -> hide_uncheked");
    $('[id^="tainou_koho"]').each(function () {
      this_id = $(this).attr("id");
      num = this_id.slice(this_id.lastIndexOf("_") + 1);
      $target_check = $('#tainou_batch_tainou_kohos_attributes_' + num + '_target');
      if (($target_check).prop("checked") == false) {
        // console.log("hide %o", this_id);
        $(this).hide();
      }
      else{
        tainou_batches_check($(this));
      }
    })
    $('#tainou_kohos_table').removeClass('table-striped');
  }
  function show_all_rows () {
    // console.log("#hide_unchecked unchecked -> show_all");
    $('[id^="tainou_koho"]').each(function () {
      $(this).show();
    })
    $('#tainou_kohos_table').addClass('table-striped');
  }
  function reset_all_rows () {
    // console.log("#hide_unchecked unchecked -> show_all");
    $('[id^="tainou_koho"]').each(function () {
      $(this).show();
      console.log($(this).children("td").eq(0).text());
      if ($(this).children("td").eq(0).text()){
      $(this).children("td").eq(0).text("");
      $(this).children("td").eq(1).children(0)[1].disabled =false;
      // syunou_kohos_check($(this));
      }
    })
    $('#tainou_kohos_table').addClass('table-striped');
  }
})
