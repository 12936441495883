// 金額入力枠
//   本来の入力フィールドは隠しておく hidden_field id="hoge"
//   カンマ入りの入力フィールドを表示 text_field id="hoge_with_comma" class="money"

$(document).on("keydown", '.money', function (e) {
  // console.log("keydown");
  // キーが押されたとき
  var k = e.key;
  // 0～9, backspace, delete, 左右矢印 以外は入力キャンセル
  if (!((k >= '0' && k <= '9')
        || k == 'Backspace' || k == 'Delete' || k == 'ArrowLeft' || k == 'ArrowRight')) {
    // console.log("unpermitted key! %o", k);
    return false;
  }
})

// keyup, blur, focus でカンマを付ける処理を行っていたが、
// 全角文字の変換途中で残ると数字部分もおかしくなるバグが発生したので、
// フォーカスが入って(focus)出る(blur)までの間はカンマを表示せずカンマ処理を行わないようにする。
// focus -> カンマを取る
// blur  -> カンマを付ける
// また、半角数字以外の入力に対する処理は以下の通り。
// 半角英数字 -> keydown で除外
// 全角数字(変換途中も含む) -> 編集中は全角のまま、blur で半角にする
// 全角数字以外(変換途中も含む) -> 編集中は見えていて、blur で除外する

$(document).on("focus", '.money', function (e) {
  // フォーカスが入ったとき => カンマをいったん消す
  // console.log("focus");
  $(this).val($(this).val().replace(/[^0-9]/g, ''));
})

$(document).on("blur", '.money', function (e) {
  // フォーカスが外れたとき => カンマ付きにする
  // console.log("blur");
  // console.log($(this).val());
  // 全角０..９を半角0..9に変更
  var num = $(this).val().replace(/[０-９]/g, function(s) {
    return String.fromCharCode(s.charCodeAt(0) - 0xFEE0);
  });
  // 先頭の0を除外。0..9以外を除外
  num = num.replace(/^[0]+/g, '').replace(/[^0-9]/g, '');
  // num は、相方の hidden_field に入れる(本来のカラムに保存する) 値になっている
  // もともと change で行っていた内容をここで行ってしまう
  $("#" + $(this).attr("id").replace("_with_comma", "")).val(num);
  // console.log($("#" + $(this).attr("id").replace("_with_comma", "")).val());
  // 3桁ごとに「,」を追加
  var result = num.replace(/(\d)(?=(\d\d\d)+$)/g, "$1,");
  $(this).val(result);
  // console.log($(this).val());

  // 以前のカンマなし変換処理
  // var numberWithComma = new Intl.NumberFormat();
  // // 0～9 以外の文字を除いて、金額フォーマット(3桁ごとにカンマあり)に変換
  // $(this).val(numberWithComma.format($(this).val().replace(/[^0-9]/g, '')));
  // console.log("%o %o", e.type, $(this).val());
})

// $(document).on("change", '.money', function () {
//   // 値が変更されたとき
//   console.log("change");
//   // 0～9 以外の文字(カンマなど)を除外して、相方の hidden_field に入れる
//   $("#" + $(this).attr("id").replace("_with_comma", "")).val($(this).val().replace(/[^0-9]/g, ''));
//   // console.log("change %o", $("#" + $(this).attr("id").replace("_with_comma", "")).val());
// })
