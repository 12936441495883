$(document).on("change", '[id^="syunou_batch_syunou_kohos_attributes"]', function() {
  SetCheckMark($(this));
});

$(document).on("keydown", '[id^="syunou_batch_syunou_kohos_attributes"]', function (e) {
  //今フォーカスがあたっている行を取得
  let $row = $(this).closest("tr");
  var colIndex = $(this).parent().index();
  if (e.keyCode === 9) {
      //-----TAB-----
      //次の行を取得
      let $row_next = $row.next("tr");
      if ($row_next.length) {
        //次の行のフォーカスにセット
        $row_next.find("td").eq(colIndex).find("input").focus();
      }
  } else if (e.keyCode === 38) {
      //-----上-----
      //前の行を取得
      let $row_next = $row.prev("tr");
      if ($row_next.length) {
        //次の行のフォーカスにセット
        $row_next.find("td").eq(colIndex).find("input").focus();
      }
  }
});

function SetCheckMark(obj) {
  var self_id = obj.attr("id");
  // console.log(self_id);
  if (self_id.match(/_target$/) != null) {
    return;
  }
  var key_length = "syunou_batch_syunou_kohos_attributes_".length;
  var target_chack_id = self_id.slice(key_length, self_id.length);
  target_chack_id = target_chack_id.slice(0, target_chack_id.indexOf("_"));
  target_chack_id = "syunou_batch_syunou_kohos_attributes_" + target_chack_id + "_target";
  // console.log(target_chack_id);
  $("#" + target_chack_id).prop('checked', true);
  // console.log($("#" + target_chack_id));
}

// [確認]したのに、[依頼]せずに他の画面(or他の年月)に移動しようとしたら警告を出す
var yearmonth; // ページに来た時点の年月
var unsaved = false; // 内容を変更したら true ([確認]で変更とみなす)
var gotoAnotherYearmonth = false; // 別の年月への移動なら true
var submitButton = false; // [依頼]ボタンsubmitなら true
var formWarningMessage = "このページを離れますか？\n依頼ボタンを押下しない場合、作業した内容が削除されます。"
var comfirmSubmitMessage = "対象の収納代行(水道代)を一括申請しますか？\n申請すると取り消せません。"
$(document).on('turbolinks:load', function() {
  unsaved = false;
  gotoAnotherYearmonth = false;
  submitButton = false;
  // ページ表示時点の年月を覚えておく(警告キャンセル時に戻すため)
  yearmonth = $('#syunou_batch_select_date').val();
  console.log(yearmonth);

  // 別ページへ移動時(aタグクリック時)の警告
  $('a').click(function(e){
    var href = $(this).attr('href')
    if(typeof(href) !== "undefined" && href != '#' && unsaved) {
      if(confirm(formWarningMessage) == false) {
        e.preventDefault()
      }
    }
  })
})
// ブラウザのタブを閉じようとした時、またはturbolinks以外のリンクをクリックした時の警告
$(window).on('beforeunload', function(e) {
  // 年月変更でOKした場合(gotoAnotherYearmonth)と、[依頼]ボタンクリック(submitButton)時は、
  // あらためて警告メッセージを出さないようにする
  if(unsaved && !gotoAnotherYearmonth && !submitButton){
    console.log('beforeunload && unsaved && !gotoAnotherYearmonth && !submitButton');
    return formWarningMessage
  }
})

//// new -> confirm -> return or submit

$(document).on("turbolinks:load", function () {
  $('#syunou_batch_submit').hide();
  $('#syunou_batch_return').hide();
  $('[id^="syunou_koho"]').each(function () {
    // syunou_kohos_check($(this));
  })

  // [確認]ボタン
  $('#syunou_batch_confirm').on("click", function() {
    $(this).hide();
    $('#syunou_batch_return').show();
    $('#syunou_batch_submit').show();
    hide_unckecked_rows();
    $("input[type='text']").prop('disabled', true);
    // 確認したら、変更したとする
    console.log('confirm -> unsaved = true');
    unsaved = true;
  })
  // [戻る]ボタン
  $('#syunou_batch_return').on("click", function() {
    $(this).hide();
    $('#syunou_batch_submit').hide();
    $('#syunou_batch_confirm').show();
    show_all_rows();
    $("input[type='text']").prop('disabled', false);
  })
  // [依頼]ボタン
  $('#syunou_batch_submit').on("click", function(e) {
    if(confirm(comfirmSubmitMessage) == false) { // もともとhtmlで記述していた[依頼]の確認
      e.preventDefault()
    }
    else { // submit に進む場合
      console.log('submit -> submitButton = true');
      submitButton = true;  // submitの時はページを離れるメッセージを出さないようにする
    }
  })
  // 年月変更
  $('#syunou_batch_select_date').on("change", function(e) {
    // 年月変更 => 確認でキャンセルなら移動しない
    if(unsaved && confirm(formWarningMessage) == false) {
      console.log('change yearmonth cancel');
      $('#syunou_batch_select_date').val(yearmonth); // 年月セレクトの表示を戻す
      e.preventDefault() // イベントをキャンセル
    }
    else {
      console.log('goto another yearmonth');
      gotoAnotherYearmonth = true; // 別の年月への移動を認めた => これ以上は警告を表示しないように
      console.log('gotoAnotherYearmonth = true');
      window.location.href = '/syunou_batches/new?select_month='+$('#syunou_batch_select_date').val()+'-1';
      $('#syunou_batch_submit').hide();
      $('#syunou_batch_return').hide();
      $('#syunou_batch_confirm').show();
      reset_all_rows();
    }
  })


  // $('#hide_unchecked').on("click", function() {
  //   if ($(this).prop("checked") == true) {
  //     hide_unckecked_rows();
  //   }
  //   else {
  //     show_all_rows();
  //   }
  // });
  function check_ajax (client_id,yearmonth,service_num) {
    var returnval;
    var param = {
                        client_id: client_id,
                        yearmonth: yearmonth,
                        service_num: service_num
                };
    var url = '/syunous/ajax';

jQuery.ajax({
method: "POST",
            url: url,
            data: param,
            dataType: 'json',
            async: false
        }).done(function(data) {
    // 通信成功時の処理
    returnval = data;

    // console.log("URL : " + url);
    // console.log("data : " + data);

}).fail(function(jqXHR, textStatus, errorThrown) {
    // 通信失敗時の処理
    console.log("ajax通信に失敗しました");
    console.log("jqXHR          : " + jqXHR.status); // HTTPステータスが取得
    console.log("textStatus     : " + textStatus);    // タイムアウト、パースエラー
    console.log("errorThrown    : " + errorThrown.message); // 例外情報
    console.log("URL            : " + url);
    return null;
}).always(function(data) {
    // 処理終了時
});
return returnval;
}

  function hide_unckecked_rows () {
    // console.log("#hide_unchecked checked -> hide_uncheked");
    $('[id^="syunou_koho"]').each(function () {
      this_id = $(this).attr("id");
      num = this_id.slice(this_id.lastIndexOf("_") + 1);
      $target_check = $('#syunou_batch_syunou_kohos_attributes_' + num + '_target');
      if (($target_check).prop("checked") == false) {
        // console.log("hide %o", this_id);
        $(this).hide();
      }
      else{
        syunou_kohos_check($(this));
      }
    })
    $('#syunou_kohos_table').removeClass('table-striped');
  }

  function syunou_kohos_check(this_tr){
    var client_id=this_tr.children("td").eq(4).children(0).val();
    var yearmonth = $("#syunou_batch_select_date").val()+'-1';
    var service_num=this_tr.children("td").eq(6).children(0).val();
    
    console.log('syunou_kohos_check');
    console.log(client_id);
    console.log(yearmonth);
    console.log(service_num);
    var returnjson=check_ajax(client_id,yearmonth,service_num);
    console.log(returnjson);
    var suidou=this_tr.children("td").eq(7).children(0).val();
    console.log(suidou);
    this_tr.children("td").eq(0).text("");
    if ((typeof suidou !== "undefined")&&(suidou==0)) {
      this_tr.children("td").eq(0).text("水道代を入力してください");
      this_tr.children("td").eq(1).children(0)[1].checked =false;
      $('#syunou_batch_submit').hide();
    }
    if(returnjson.return=='already'){
      this_tr.children("td").eq(0).text("既に申請されています");
      this_tr.children("td").eq(1).children(0)[1].checked =false;
      this_tr.children("td").eq(1).children(0)[1].disabled =true;
      $('#syunou_batch_submit').hide();
    }else if(returnjson.return=='overdue'){
      this_tr.children("td").eq(0).text("申請の期日を超えています");
      this_tr.children("td").eq(1).children(0)[1].checked =false;
      this_tr.children("td").eq(1).children(0)[1].disabled =true;
      $('#syunou_batch_submit').hide();
    }
    // console.log($(this).children("td").eq(0));
  }
  function show_all_rows () {
    // console.log("#hide_unchecked unchecked -> show_all");
    $('[id^="syunou_koho"]').each(function () {
      $(this).show();
    })
    $('#syunou_kohos_table').addClass('table-striped');
  }
  function reset_all_rows () {
    // console.log("#hide_unchecked unchecked -> show_all");
    $('[id^="syunou_koho"]').each(function () {
      $(this).show();
      console.log($(this).children("td").eq(0).text());
      if ($(this).children("td").eq(0).text()){
      $(this).children("td").eq(0).text("");
      $(this).children("td").eq(1).children(0)[1].disabled =false;
      // syunou_kohos_check($(this));
      }
    })
    $('#syunou_kohos_table').addClass('table-striped');
  }
})
