require("./input_field");
//require("./arrearage");
//require("./payment");
require("./arrearage2");
require("./download_all");
require('./jquery.jpostal');
require("./additional_contract");
require("./tainou_batch");
require("./syunou_batch");
require("./file_thumbnail");
require("./rent_change_request");
