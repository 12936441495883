$(document).on("change", '[id^="document_file"]', function(event) {
  ShowThumbnail(event, $(this));
});

function ShowThumbnail(event, self) {
  // console.log(self.attr("id"));
  var targetObj = "#" + self.attr("id").replace("file", "thumbnail");
  // console.log(targetObj);

  var fileData = event.target.files[0];
  // console.log(fileData);
  // console.log(fileData.type);
  var reader = new FileReader();
  reader.readAsDataURL(fileData);

  reader.onload = function(){
    var dataUrl = reader.result;
    if (fileData.type.match('image.*')) {
      $(targetObj)[0].innerHTML = "<img src='" + dataUrl + "' width=200>";
    }
    else if (fileData.type.match('application/pdf')) {
      $(targetObj)[0].innerHTML = "<object data='" + dataUrl + "' type=application/pdf width=200>";
    }
    else {
      $(targetObj)[0].innerHTML = "";
    }
    // console.log($(targetObj)[0].innerHTML);
  }
}
